import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { USER_TYPES } from '../../utils/enums';
import { fileNameByUrl, fileTypeByUrl } from '../../utils/files';
import { onGetUserTypeFromToken } from '../../utils/general';


const useUser = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {

            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/user/all', {params:params})
            result.data = request.data.data?.users??[]
            result.count = request.data.data.count
            return result
        },
        // Get list of all my patients
        // onGetAllPatientsByNutritionist: async(params) => {
        //     let result = {
        //         data:[], 
        //         count:0
        //     }
        //     const request = await private_server.get('/user/all/nutritionist', {params:params})
        //     result.data = request.data.data.users
        //     result.count = request.data.data.count
        //     return result
        // },
        // Get list of all subusers of type 3 and 4 of a client
        onGetAllbyClient: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/user/all/client', {params:params})
            result.data = request.data.data.users
            result.count = request.data.data.count
            return result
        },
        // Get list of all users of type nutriotionist
        onGetAllNutritionist: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/user/all/client', {params:params})
            result.data = request.data.data.users
            result.count = request.data.data.count
            return result
        },
        onGetMe: async() => {
            let result = null
            const request = await private_server.get(`/user/me`)
            result = request.data.data.user
            return result
        },
        onGet: async(iduser) => {
            const userType = onGetUserTypeFromToken()
            let result = null
            let request
            if(userType === USER_TYPES.ADMIN){
                request = await private_server.get(`/user/${iduser}`)
            }else{
                request = await private_server.get(`/user/${iduser}/client`)
            }
            result = request.data.data.user
            return result
        },
        onGetPharmacy: async() => {
            let result = null
            const request = await private_server.get(`/pharmacy/doctor/me`)
            result = request.data.data.pharmacy_data
            return result
        },
        onGetAssistantPharmacy: async() => {
            let result = null
            const userpharmacy_rq = await private_server.get('/pharmacyuser/list/me')
            const pharmacy_user = userpharmacy_rq.data.data.pharmacy_users[0]
            const request = await private_server.get(`/pharmacy/${pharmacy_user?.pharmacy_id}`)
            result = request.data.data.pharmacy_data
            return result
        },
        onAdd: async(data2send) => {
            const userType = onGetUserTypeFromToken()
            let result = null
            if(userType === USER_TYPES.ADMIN){
                const request = await private_server.post('/user', data2send)
                result = request.data.data?.user?.id_user
            }else{
                const request =  await private_server.post(`/user/nutritionist`, data2send)
                result = request.data.data?.user?.id_user
            }
            return result
        },
        onAddbyClient: async(data2send) => {
            let result = null
            const request = await private_server.post('/user/client', data2send)
            result = request.data.data?.user?.id_user
            return result
        },
        onEdit: async(iduser, data2send) => {
            const userType = onGetUserTypeFromToken()
            if(userType === USER_TYPES.ADMIN){
                await private_server.patch(`/user/${iduser}`, data2send)
            }else{
                await private_server.patch(`/user/${iduser}/nutritionist`, data2send)
            }
        },
        onEditMe: async(data2send) => {
            await private_server.patch(`/user/me`, data2send)
        },
        onEnable: async(iduser) => {
            await private_server.post(`/user/${iduser}/enable`)
        },
        onDisable: async(iduser) => {
            await private_server.post(`/user/${iduser}/disable`, {disabled_reason_id:1})
        },
        onUpdatePassword: async(data2send) => {
            await private_server.post(`/user/me/passwordchange`, data2send)
        },
        onDelete: async(id) => {
            await private_server.delete(`/user/${id}`)
        },
        onUpdatePicture: async(id, file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {name:'image',ext:fType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/user/${id}/image`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.url, file, options);
        },
        onUpdatePictureMe: async(file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {name:'image',ext:fType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/user/me/image`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.url, file, options);
        },
        onGetBlobFile: async(url, name) => {
            const config = { responseType: 'blob' };
            //console.log(`${CONFIG_SETTINGS.S3_SERVER_URL}${url}`)
            const response = await generic_server.get(`${CONFIG_SETTINGS.S3_SERVER_URL}${url}`, config);
            return new File([response.data], name??'image-velfare', { type: response.data.type })
        },

    }

    return {
        actions
    }
}
 
export default useUser;